import { createRouter, createWebHistory } from "vue-router"; 

import landingPage from '@/components/landingPage.vue';
import aboutPage from '@/components/about.vue';
import dienstenPage from '@/components/diensten.vue';


import AaltenPage from "@/components/SeoAalten.vue";
import achterhoekPage from "@/components/SeoAchterhoek.vue";




const routes = [
    {
        path: '/',
        name: 'landingpage',
        component: landingPage,
        
    },

    {
        path: '/about',
        name: 'about',
        component: aboutPage, 
     
    },

    {
        path: '/diensten',
        name: 'diensten',
        component: dienstenPage,
     
    },

    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/components/contact.vue'), //lazy loading
     
    },

    {
        path: '/privacybeleid',
        name: 'privacybeleid',
        component: () => import('@/components/privacybeleid.vue'), //lazy loading
     
    },

    {
        path: '/achterhoek',
        name: 'achterhoek',
        component: achterhoekPage,
     
    },

    {
        path: '/aalten',
        name: 'aalten',
        component: AaltenPage,
     
    },
    


]

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
})


export default router
