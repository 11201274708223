<script >
import mixins from '@/mixins/mixins'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default{
  name: 'dienstVue',
  props: ['dienst'], // Haalt de var Dienst die is mee gegeven op uit de diensten.vue.
  mixins: [mixins],
}
</script>

<template>

        <div class="mb-[70px] pt-[10px] md:container mx-auto">
          <div class="pb-10">
            <h1 class="text-black">{{ dienst.dienst}}</h1>
            <p class="md:w-[70%]"> {{dienst.tekst}} </p>
          </div>
        </div>
        
         
          <img class='rounded-xl' :src="getImgUrl(dienst.img)" :alt="dienst.img">
       
        
          <h1 class="text-black md:pt-[100px]">Waarom een {{ dienst.dienst }}?</h1>
          <div class="md:grid md:grid-cols-2 md:gap-5 dienstContainer md:pb-[100px]">
            <div v-for='voordeel, index in dienst.voordelen' :key="index">
              <div class=" bg-DonkerColor mt-5 rounded-md p-5">
                  <h3 class="">{{ voordeel.titel }}</h3>
                  <p class="text-ligteColor">{{ voordeel.beschrijving }}</p>
              </div> 
            </div>
          </div>
</template>


