
import posthog from  'posthog-js'

export default{
    install(app) {
        app.config.globalProperties.$posthog = posthog.init(
            "phc_QL9ApxfGpfzoLsKD1d0VwfwqLFgjTNTPuNdUMcOIrft",
            {
                api_host:'https://us.i.posthog.com',
                
            }
        )
    }
}
