<template>
  <div class='md:container md:mx-auto pt-[100px] px-3 md:px-0'>
    
    
      <div class='mb-[50px]'>
        <h1 class="md:w-[50%]">Websites en Webapplicaties op Maat voor ZZP'ers en MKB'ers in de Achterhoek</h1>
        <p class="md:w-[70%]">
          Bent u een ZZP'er of MKB'er in de Achterhoek en op zoek naar een professionele website of webapplicatie? Wij ontwikkelen op maat gemaakte digitale oplossingen die aansluiten bij de unieke behoeften van kleinere bedrijven en zelfstandigen. Of het nu gaat om een nieuwe website of een handige webapplicatie, wij zorgen dat uw online aanwezigheid uw bedrijf naar een hoger niveau tilt.
        </p>

        
          <button class="border-2 bg-DonkerColor border-hoverColor p-2 px-5 mx-2 my-5 rounded-full hover:text-black hover:bg-hoverColor transition ease-in-out duration-300"><router-link to="/diensten"> Bekijk de mogelijkheden <i class="fa-solid fa-arrow-right"></i></router-link></button>
             
        <div class="w-full block md:hidden pt-5">
        <img src="../assets/sfeerfoto5.jpg" alt="website-ontwikkeling" class="block w-full h-full rounded-xl">
      </div>
      </div>
      
     
    
  
    
    <div class='md:grid md:grid-cols-2 md:pt-[100px] gap-5'>
      <div class=''>
        <h3>Waarom een website op maat voor ZZP’ers en MKB?</h3>
        <p>
          Als zelfstandige of MKB’er is het belangrijk om online op te vallen. Met een op maat gemaakte website kunt u:
        </p> 
        <ul class="list-decimal list-inside font-bold">
          <li class="font-semibold p-1">Professioneel overkomen met een ontwerp dat uw merk en diensten benadrukt.</li>
          <li class="font-semibold p-1">Lokale vindbaarheid verbeteren door SEO-optimalisatie gericht op Aalten en omgeving.</li>
          <li class="font-semibold p-1">Gemakkelijk updates doorvoeren en functionaliteiten uitbreiden wanneer uw bedrijf groeit.</li>
        </ul>

      </div>

      
      <div class="w-full">
        <img src="../assets/sfeerfoto2.jpg" alt="website-ontwikkeling" class="block w-full h-full rounded-xl">
      </div>
    </div>
  
  
    
    <div class='md:grid md:grid-cols-2  py-[100px] md:pt-[100px] gap-5'>
        <div class="w-full hidden md:block">
          <img src="../assets/sfeerfoto6.jpg" alt="App-ontwikkeling" class="block w-full h-full rounded-xl ">
        </div>
    
        <div class=''>
          <h3>Webapplicaties, Portalen en Mobiele Apps voor lokale ondernemers</h3>
            <p>
              Naast websites bieden wij ook webapplicaties, portalen en mobiele apps om bedrijfsprocessen te stroomlijnen en uw klanten betere service te bieden. Denk bijvoorbeeld aan:
            </p>

            <ul class="list-decimal list-inside font-bold">
          <li class="font-semibold p-1">Online boekingssystemen</li>
          <li class="font-semibold p-1">Klantportalen voor communicatie en diensten.</li>
          <li class="font-semibold p-1">Facturatie- en klantbeheertools</li>
          <li class="font-semibold p-1">Mobiele apps voor klantinteractie of interne processen</li>
        </ul>

        <p>
          Met een maatwerk oplossing afgestemd op uw bedrijfsprocessen bespaart u tijd, verbetert u de gebruikerservaring en verhoogt u de efficiëntie.
      </p>
      <button class="border-2 bg-DonkerColor border-hoverColor p-2 px-5 mx-2 my-5 rounded-full hover:text-black hover:bg-hoverColor transition ease-in-out duration-300"><router-link to="/diensten"> Bekijk de mogelijkheden <i class="fa-solid fa-arrow-right"></i></router-link></button>

      <div class="w-full pt-5 md:pt-0 md:hidden">
          <img src="../assets/sfeerfoto6.jpg" alt="App-ontwikkeling" class="block w-full h-full rounded-xl ">
        </div>
        </div>
    </div>
  
    
    
    
        <div class='md:pt-[100px]'>
          <h3 class="md:w-[30%]">Lokale expertise voor ZZP en MKB in de Achterhoek</h3>
         
          <p class="md:w-[70%]">Wij werken samen met ZZP’ers en MKB’ers in de Achterhoek en begrijpen de uitdagingen van kleine ondernemers. Of u nu een nieuwe website, webapplicatie, portaal of mobiele app nodig heeft, wij bieden flexibele en schaalbare oplossingen die met u meegroeien. Neem vandaag nog contact met ons op voor een vrijblijvend gesprek en ontdek wat wij voor uw bedrijf kunnen betekenen!</p>
          <button class="border-2 border-hoverColor p-2 px-5 mx-2 my-5 rounded-full hover:text-black hover:bg-hoverColor transition ease-in-out duration-300"><router-link to="/contact"> Kom in contact <i class="fa-solid fa-arrow-right"></i></router-link></button> 
        </div>
  
  
  
  </div>
</template>

<script>

export default {
name: 'achterhoekPage',
}

</script>
