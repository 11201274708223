//Gedeelde map met functies 

export default {
    
    methods: {
        getImgUrl(img){
            return require('../assets/' + img);
        },

    }
}
