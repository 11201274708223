<script>
import dienstenData from '@/data/content_diensten';
import dienst from './dienst.vue';
import { ref, computed } from 'vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'ontdekPage',
  components: {
    dienst,
  },

  setup() {
    // Dynamische paginatitel
    const pageTitle = ref('Software diensten');
    
    // Voeg meta tags toe met useHead
    useHead({
      title: pageTitle.value,
      meta: [
        {
          name: 'description',
          content: 'Alle diensten die ik aanbied. Een maatwerk software oplossing nodig om verder te komen?',
        },
        {
          property: 'og:title',
          content: 'Dynamische Open Graph Titel',
        }
      ]
    });

    // Data uit dienstenData
    const diensten = dienstenData.diensten;
    const dienstID = ref(0);

    // Methodes
    const selectDienst = (index) => {
      dienstID.value = index;
    };

    // Computed property voor de geselecteerde dienst
    const selectedDienst = computed(() => {
      return { ...diensten[dienstID.value] };
    });

    return {
      diensten,
      dienstID,
      selectDienst,
      selectedDienst,
      pageTitle,
    };
  }
};
</script>

<template>
  <div class="w-full bg-cover bg-no-repeat bg-center relative bg-[url('../src/assets/sfeerfoto2.jpg')] h-[32rem] md:h-[32rem]">
    <div class="flex items-center justify-center h-full w-full bg-DonkerColor bg-opacity-70">
      <div class="absolute md:top-[240px] w-5/6 lg:w-2/6">
        <div class="p-5 w-full rounded-md h-fill shadow-md bg-ligteColor opacity-85 hover:opacity-100 transition duration-300 ease-in-out">
          <h2 class="text-[30px] text-black">Waar bent u naar opzoek?</h2>
          <ul class="py-3 p-2 cursor-pointer">
            <li 
              class="py-1 hover:bg-hoverColor transform duration-500 rounded-md p-2 md:text-[20px]"
              v-for="(dienst, index) in diensten" 
              :key="dienst.id"
              @click="selectDienst(index)"
              :title="dienst.details">
              <p class="md:w-[50%] text-textColor"><i class="fa-solid fa-check fa-lg"></i> {{ dienst.dienst }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-donkerColor">   
    <div class="md:container md:mx-auto px-5 md:px-0 pt-12 overflow-hidden">
      <dienst v-if="selectedDienst" :dienst="selectedDienst" />
    </div>
  </div>
</template>
