<template>
  <headerSection /> 
  <transition name='fadeCoockies'>
   <coockiebanner v-if='showBanner' @hideBanner='showBanner = false' />
  </transition>
  <router-view />
  <footerSection />




 

</template>

<script>
  import coockiebanner from './components/coockie.vue'
  import headerSection from './components/header.vue';
  import footerSection from './components/footer.vue';
 

  export default {
    name: 'App',
    components: {
      headerSection,
      footerSection,
      coockiebanner,
    },

    data(){
      return{
        showBanner: (this.$posthog.has_opted_in_capturing() || this.$posthog.has_opted_out_capturing()) // kijkt of er coockies zijn, als dat zo is dan coockiebanner.
      }
    }


  }
 
</script>


<style scoped>
  .fadeCoockies-enter-active, .fadeCoockies-leave-active {
    
    transition: opacity .5s;
  }
  .fadeCoockies-enter, .fadeCoockies-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>


