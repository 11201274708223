<template>
<footer class="">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="py-16 grid lg:grid-cols-1 items-center">
            <a href="/" class="flex justify-center">
                <img src="../assets/logo-4.png" alt="Bedrijf logo" class="w-[200px]">
            </a>

            <div class="grid grid-cols-2 xl:grid-cols-3 gap-[5px] justify-center w-fill"> 
                <div class="p-2 w-full">
                    <ul class="text-lg text-center sm:grid md:items-center justify-center transition-all duration-500">
                        <h4 class="text-black">Bekijk snel..</h4>
                        <li><router-link to="/" class="text-black hover:text-gray-400">Home</router-link></li>
                        <li><router-link to="/about" class="text-black hover:text-gray-400">Over ons</router-link></li>
                        <li><router-link to="/diensten" class="text-black hover:text-gray-400">Diensten</router-link></li>
                        <li><router-link to="/contact" class="text-black hover:text-gray-400">Contact</router-link></li>
                    </ul>
                </div>

                <div class="p-2 w-full">
                    <ul class="text-lg text-center items-center justify-center transition-all duration-500">
                        <h4 class="text-black">Ik ben opzoek naar een..</h4>
                        <li><router-link to="/diensten" class="text-black hover:text-gray-400">Portal</router-link></li>
                        <li><router-link to="/diensten" class="text-black hover:text-gray-400">Web application</router-link></li>
                        <li><router-link to="/diensten" class="text-black hover:text-gray-400">IOS / Android App</router-link></li>
                        <li><router-link to="/diensten" class="text-black hover:text-gray-400">Website</router-link></li>
                    </ul>
                </div>

                <div class="p-2 w-full hidden lg:block">
                    <ul class="text-lg text-center items-center justify-center transition-all duration-500">
                        <h4 class="text-black">Contact</h4>
                        <li><i class="fa-regular fa-envelope fa-lg"></i> <a href="mailto:developmentmebel@gmail.com">developmentmebel@gmail.com</a></li>
                        <li><i class="fa-solid fa-phone-volume fa-lg"></i> <a href="tel:+31624539865">(+31) 6 24 53 98 65</a></li>
                        <li><i class="fa-regular fa-building fa-lg"></i> KvK: 95026142</li>
                        <li><i class="fa-solid fa-location-dot fa-lg"></i> De Hare, Aalten</li>
                    </ul>
                </div>
            </div>

            <div class="p-2 w-full block lg:hidden">
                <ul class="text-lg text-center items-center justify-center transition-all duration-500">
                    <h4 class="text-black">Contact</h4>
                    <li><i class="fa-regular fa-envelope fa-lg"></i> <a href="mailto:developmentmebel@gmail.com">developmentmebel@gmail.com</a></li>
                    <li><i class="fa-solid fa-phone-volume fa-lg"></i> <a href="tel:+31624539865">(+31) 6 24 53 98 65</a></li>
                    <li><i class="fa-regular fa-building fa-lg"></i> KvK: 95026142</li>
                    <li><i class="fa-solid fa-location-dot fa-lg"></i> De Hare, Aalten</li>
                </ul>
            </div>
        </div>

        <div class="py-7 border-t border-gray-700">
            <div class="flex items-center justify-center">
                <span class="text-gray-400">©<router-link to="/">DevelopmentteMebel</router-link>, All rights reserved.</span>
                <router-link to="/aalten">Aalten</router-link>
                <a href="/achterhoek">Achterhoek</a>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
    name: 'footerPage',
}
</script>
