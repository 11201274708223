import { createApp } from 'vue'
import App from './App.vue'
//import axios from 'axios'
import './index.css'
import router from './routing/router'
import posthog from './plugins/posthog'
import { createHead } from '@vueuse/head'

// Maak de head manager aan
const head = createHead();
createApp(App)
.use(router) // .use(..) moet boven aan komen te staan. 
.use(head)
.use(posthog)

.mount('#app') // deze moet onderaan komen te staan. 


