<template>
<div class='bg-ligteColor p-5 md:container  border-0 rounded-xl animate-fadeInDown absolute m-5 md:m-0 md:top-[20%] md:left-[40%] md:w-[500px] z-[999] shadow-black shadow-md'>
  <h3 class='text-xl text-bold text-black'><i class="fa-solid fa-cookie-bite"></i> Cookiebeleid </h3>

  <p class='text-textColor'>
    Wij maken gebruik van cookies om de website-ervaring te verbeteren met behulp van Google Analytics. 
   <br>
    U kunt ervoor kiezen om cookies te accepteren of te weigeren. Voor meer informatie verwijzen we u naar de <router-link to='/privacybeleid'>Privancy beleid</router-link>
  </p>

  <div class=" grid items-center">
    <button @click='acceptCoockie' class='border-hoverColor border-2 p-3 mt-4 rounded-xl hover:bg-hoverColor transition duration-300 text-black'> <i class="fa-solid fa-cookie-bite"></i> Accepteren </button>
    <button @click='weigerCoockie' class='block p-2 m-2 '>Weigeren</button>
  </div>
</div>
</template>


<script>
export default {
name: 'CoockieVue',

  methods: {
    acceptCoockie(){
      console.log('accept coockie');
      this.$posthog.opt_in_capturing(); // Zet coockie op true na klik
      this.$emit('hideBanner'); // communiceert met de hidebanner in de app.vue component
    },
    weigerCoockie(){
      console.log('weiger coockie');
      this.$posthog.opt_out_capturing(); // Zet coockie op false na klik 
      this.$emit('hideBanner');  // communiceert met de hidebanner in de app.vue component
    }
  }

}
  
</script>

