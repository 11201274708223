
<script setup>
import { onMounted, ref } from 'vue';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import { useHead } from '@vueuse/head';
// Dynamische paginatitel
const pageTitle = ref('Maatwerk IT Oplossingen');

// Voeg meta tags toe met useHead
useHead({
  title: pageTitle.value,
  meta: [
    {
      name: 'description',
      content: `Wij, Developmenttemebel, maken IT oplossingen die je verder helpen. Waar je zelf aan de knoppen zit voor de ontwikkeling van jou applicatie`,
    },
    {
      property: 'og:title',
      content: 'DevelopmentteMebel',
    },
    {
        name: 'robots',
        content: 'index, follow',
    },
  ]
})


onMounted(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
        gsap.utils.toArray(".panel").forEach((panel, i) => {
        
            ScrollTrigger.create({
                // animation: tl,
                trigger: panel,
                start: "top top",
                pin: true,
                pinSpacing: false,
            });
        });

        gsap.to('.steranimatie', {
        rotation: 360,
        scrollTrigger: {
            trigger: "#section3",
            markers: false,
            start: 'top bottom',
            end: 'top top',
            scrub:true,
            },
        })
    });
});

</script>




<style scoped>
  @keyframes scroll {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scroll 25s linear infinite;
  }
</style>



<template>

    <section id="section1" class="panel w-screen h-[100vh] relative">
        <div class="w-full h-full bg-cover bg-center bg-[url('../src/assets/worldSoftware.jpg')] bg-no-repeat">
            <div class="absolute top-[20%] md:left-[20%] left-5">
                
                <h1 class="lg:text-[75px] text-[35px]">DevelopmentteMebel</h1>
                <h2 class="lg:text-[34px] text-[28px]">Jouw IT oplossing op maat.</h2>
                <h2 class="text-hoverColor font-bold">New Website coming soon! <br> <a  class='text-[35px] text-hoverColor' href="https://swenssoftware.nl/">SwensSoftware</a></h2>
            
                
                <div class="">
                       <button class="border-2 bg-ligteColor border-groenecolorv2 shadow-lg p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-black' to="/Contact"> Aan de slag <i class="fa-solid fa-arrow-right"></i></router-link></button>
                </div>
            </div>
        </div>
      

    </section>

    <section id="section2" class="panel  tweede2 w-screen h-[170vh] md:h-[100vh]">
        <div class="bg-ligteColor grid lg:grid-cols-3 lg:grid-rows-2  " >
            
                <div class="hidden lg:grid">
                    <h1 class="text-black text-[45px] font-semibold md:-rotate-90 absolute top-[50%] left-10"> Onze diensten</h1>
                </div>
          
            <div class="grid md:grid-cols-2 col-span-2 gap-2 h-dvh md:h-screen p-5">
                
                <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>Web Applicatie laten maken</h1>
                        <p>Heb je een webapplicatie nodig die altijd en overal toegankelijk is via de webbrowser? Voor zowel op de telefoon, tablet en desktop te gebruiken. En zoek je een oplossing die makkelijk schaalbaar is? Lees snel verder!</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 1</span>
                    </div>
                </div>
            
                <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>App Applicatie laten maken</h1>
                        <p>Een mobiele app is een softwaretoepassing die speciaal is ontworpen om te worden uitgevoerd op een mobiel apparaat, zoals een smartphone of tablet</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 2</span>
                    </div>
                </div>

            
                <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>Portaal laten maken</h1>
                        <p>In de huidige digitale wereld wordt het voor organisaties steeds belangrijker om informatie en diensten op een gestroomlijnde en toegankelijke manier aan te bieden. Hier komt een portaal in beeld, een krachtig hulpmiddel dat je organisatie kan helpen om efficiëntie te verhogen.</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 3</span>
                    </div>
                </div>

              <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>Website laten maken</h1>
                        <p>Een website is het eerste wat je klanten zien als je ze online opzoeken. Wij maken websites op maat, wil je zelf aan het roer staat? Dat is mogelijk. Of laat je wensen achter en wij gaan voor je aan de slag en zetten een mooi design voor je neer.</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 4</span>
                    </div>
                </div>

            </div>
            
        </div>
    </section>

    <section id="section3" class="panel derde3 w-screen h-auto md:h-[100vh] bg-ligteColor mt-[670px] md:mt-[0px]">
        <div class="grid lg:grid-cols-2 ">
            <div class="md:h-[100vh] grid place-items-center">
                <div class="grid place-items-start pl-[30px]">
                    <h1 class="text-black text-[50px]">De IT oplossing op maat.</h1>
                    <p class="w-[90%] lg:w-[50%] my-5">Ik ontwikkel op maat gemaakte web applicaties en app store applicaties voor zowel Android als Apple. Daarnaast bieden wij ook de mogelijkheid om portals te creëren en op maat gemaakte websites. Mijn focus ligt vooral bij MKB en ZZP ondernemingen.</p>
                    <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact"> Aan de slag <i class="fa-solid fa-arrow-right"></i></router-link></button>
      
                </div>
                <div class="grid place-items-start pl-[30px]">
                    <h1 class="text-black text-[50px]">En nog veel meer</h1>
                    <p class="w-[90%] lg:w-[50%] my-5">Bij al deze IT oplossingen komt nog veel meer bij kijken. Denk dan bijvoorbeeld aan hosting, service en onderhoud maar ook een database. Geen zorgen, ik ga je hiermee helpen en samen gaan we de uitdaging aan.</p>
                    <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact"> Lees meer.. <i class="fa-solid fa-arrow-right"></i></router-link></button>
      
                </div>
            </div>
            <div class="bg-groenecolorv2 h-[100vh] relative hidden lg:block">

                <div class="bg-ligteColor h-[60%] w-[40%] rounded-xl absolute top-[15%] grid -left-[20%] border-4 border-groenecolorv2">
                    <div class="text-center pt-3">
                        <h1 class=" text-black text-[50px] ">Welcome <br> Back  </h1>
                    </div>
                    <div class="grid place-items-center mb-5">
                        <img src="../assets/halfstar.png" alt="symbool">
                    </div>

                    <div class=" grid place-items-center mb-10">
                        <div class="bg-groenecolorv2 text-white rounded-full h-10 w-[60%] flex justify-start pl-4 pt-1.5"><p>User Name</p></div>
                        <div class="bg-groenecolorv2 text-white rounded-full h-10 w-[60%] flex justify-start pl-4 pt-1.5"><p>Password</p></div>
                    </div>

                    <div class="border-t-2 border-black grid place-items-center">
                        <p>Log in</p>
                    </div>
                </div>

                <img class=" absolute top-10 left-[30%] steranimatie" src="../assets/ster2.png" alt="">
                <img class=" absolute top-[25%] left-[50%]" src="../assets/swingarrow.png" alt="">
                <img class=" absolute bottom-[8%] left-[8%]" src="../assets/vlek.png" alt="">
                <img class=" absolute bottom-[10%] right-[8%] w-[100px]" src="../assets/arrowtopleft.png" alt="">
            </div>
        </div>
    </section>

    <section id="section4" class="panel vierde4 w-screen md:h-[100vh] bg-ligteColor relative">
        <div class="grid md:grid-cols-2">
            <div class="md:h-[100vh] bg-groenecolorv2">
                <div class="grid place-items-center">
                    <img src="../assets/sfeerfoto6.jpg" alt="applicatie op maat">
                </div>    
            </div>
            <div class="md:h-[100vh] ">
                
               <div class="w-[85%] lg:w-[50%] mt-10 ml-[30px]">
                <h1 class="text-black">Get to know me</h1>
                <p class="my-5 w-[100%]"> Ik ben Swen, een Full-Stack Developer met 4+ jaar ervaring. 4+ jaar als    Front-end developer en 3 jaar als Back-end developer. 
                        <br>
                        Ik houd me graag bezig met het ontwikkelen van web applicaties, portals en apps. Mijn streven is dat de applicatie veilig is, snel maar ook dynamisch moet zijn en snel moet zijn.
                        Ik ben dan ook dagelijks bezig met de nieuwste ontwikkelingen van technologie. 
                        <br>
                        Ben je opzoek naar handige tools die je bedrijf geautomatischeerd kan laten lopen d.m.v webb applicaties, IOS App of Android App of een portaal? 
                        Laten we dan samen de uitdaging aan gaan!</p>
                <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact"> Contact me <i class="fa-solid fa-arrow-right"></i></router-link></button>
      
               </div>
            </div>
        </div>
        <div class="w-screen md:absolute md:bottom-0">
            <div class="flex justify-center my-5 mx-5">
                <h1 class="text-black">De nieuweste technologiën waar ik graag mee werk</h1>
            </div>
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img src="../assets/logo.png" alt="vueLogo" class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="ViteLogo" class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="TailwindLogo" class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="Firebaselogo" class='w-25 h-20  mx-20'>
                    <img src="../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="NodeJsLogo" class='w-25 h-20 mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    
                    <img src="../assets/logo.png" alt="vueLogo"  class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="ViteLogo"  class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="TailwindLogo"  class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="Firebaselogo"  class='w-25 h-20 mx-20'>
                    <img src="../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
      
    </section>

    <section id="section4" class="panel vijfde5 w-screen h-[1px] md:h-[100vh] -z-50">
    </section>
</template>



<style scoped>
.panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
