<template>
<div class="flex bg-ligteColor md:bg-transparent md:absolute justify-between items-center h-[55px] md:h-[50px] mx-auto md:container z-20 md:mt-[20px]">
 <router-link to="/" class="ml-[20px] md:ml-[45px] w-[140px] md:w-[180px] pt-5 z-10 "><img alt="developmenttemebel" src="../assets/logo-4.png"></router-link>
   
      <div @click="openMenu()"  class="list-none h-[22px] items-center mr-[25px] md:hidden grid justify-center cursor-pointer z-20">
        <div class="" :class="open ? 'duration-300 ease-in-out transform rotate-45 w-[22px] h-[2px] origin-top-left bg-DonkerColor' : 'w-[26px] h-[2px] bg-DonkerColor transform duration-300'" ></div>
        <div class="" :class="open ? 'w-0 h-0 opacity-0 transform ease-out duration-400 bg-DonkerColor' : 'w-[26px] h-[2px] bg-DonkerColor transform duration-300'" ></div>
        <div class="" :class="open ? 'duration-300 ease-in-out transform -rotate-45 w-[22px] h-[2px] origin-bottom-left bg-DonkerColor' : 'w-[26px] h-[2px] bg-DonkerColor transform duration-300'" ></div>
         
      </div> 
    

      

  
      <ul class=" md:sticky bg-ligteColor md:bg-transparent  z-10 md:flex md:items-center lg:static text-textColor absolute md:w-auto w-full h-auto md:top-20 top-[53px] duration-300 ease-in" :class="[open ? 'left-0' : 'left-[-100%]']">
        
        
        <li  @click="openMenu()" class=" md:mx-4 md:my-0 p-2 mx-2 ease-in duration-300 hover:text-hoverColor"><router-link to="/" class="text-black md:text-xl text-[18px] transition duration-400 hover:text-hoverColor font-black group "> Home <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
        <li  @click="openMenu()" class=" md:mx-4 md:my-0 p-2 mx-2 ease-in duration-300 hover:text-hoverColor "><router-link to="/diensten" class="text-black md:text-xl text-[18px] transition duration-400 hover:text-hoverColor font-black  group"> Diensten <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
        <li  @click="openMenu()" class=" md:mx-4 md:my-0 p-2 mx-2 ease-in duration-300 hover:text-hoverColor "><router-link to="/about" class=" text-black   md:text-xl text-[18px] transition duration-400 hover:text-hoverColor font-black  group"> Over mij <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
        <li  @click="openMenu()" class=" md:mx-4 md:my-0 p-2 mx-2 ease-in duration-300 hover:text-hoverColor"><router-link to="/contact" class=" text-black  md:text-xl text-[18px] transition duration-400 hover:text-hoverColor font-black group"> Contact <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
      

        <!-- <button id="theme-toggle" type="button" class="text-gray-500 bg-orange-400 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
            <svg id="theme-toggle-dark-icon" class="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>
            <svg id="theme-toggle-light-icon" class="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
        </button>  -->
      </ul>
  
    

  </div>    

</template>
  
<script>
export default{
    name: 'headerPage',


    data(){
          return{
              open: false,
              user: null,
          }
      },

    methods:{
      openMenu(){
        this.open = !this.open;
      },
    }

}
</script>




