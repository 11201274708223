const contentDiensten = {
    diensten: [
        {
            id: 1,
            dienst: 'App Applicatie',
            tekst: 'Een mobiele app is een softwaretoepassing die speciaal is ontworpen om te worden uitgevoerd op een mobiel apparaat, zoals een smartphone of tablet, met een specifiek besturingssysteem zoals iOS of Android. In tegenstelling tot webapplicaties, die toegankelijk zijn via een webbrowser, worden mobiele apps lokaal op het apparaat geïnstalleerd via de App Store of Google Play Store. Gebruikers kunnen een mobiele app openen door het bijbehorende pictogram op hun startscherm aan te raken, wat een directe en vaak snellere toegang tot de app-functies biedt zonder afhankelijk te zijn van een browser of constante internetverbinding.',
            voordelen: [
                {
                    titel: 'Offline toegang',
                    beschrijving: 'Een van de grootste voordelen van native apps is de mogelijkheid om offline te werken. Apps kunnen gegevens opslaan op het apparaat zelf, waardoor gebruikers toegang hebben tot bepaalde functionaliteiten en content zonder dat ze verbonden hoeven te zijn met internet.',
                },
                {
                    titel: 'Uitstekende prestaties',
                    beschrijving: 'Native apps zijn doorgaans sneller en responsiever dan webapplicaties, omdat ze direct op het apparaat draaien en geoptimaliseerd zijn voor de hardware. Dit kan cruciaal zijn voor bedrijven die een hoogwaardige, prestatiegerichte ervaring willen bieden',
                },
                {
                    titel: 'Gebruik van pushmeldingen',
                    beschrijving: 'Native apps zijn doorgaans sneller en responsiever dan webapplicaties, omdat ze direct op het apparaat draaien en geoptimaliseerd zijn voor de hardware. Dit kan cruciaal zijn voor bedrijven die een hoogwaardige, prestatiegerichte ervaring willen bieden',
                },
                 {
                    titel: 'App store ecosysteem',
                    beschrijving: 'Door een app in de App Store of Google Play Store te plaatsen, krijgen bedrijven toegang tot een groot potentieel publiek. De zichtbaarheid in deze winkels biedt ook marketingmogelijkheden via app store optimalisatie (ASO), reviews, en featured-lijsten. Dit kan helpen bij het vergroten van de naamsbekendheid en het aantrekken van nieuwe klanten.',
                },
            ],
            img: 'sfeerfoto3.jpg'
        },
        {
            id: 2,
            dienst: 'Web Applicatie',
            tekst: 'Een webapplicatie is een softwaretoepassing die wordt uitgevoerd in een webbrowser. In tegenstelling tot traditionele desktopapplicaties die lokaal op een computer worden geïnstalleerd en uitgevoerd, worden webapplicaties gehost op een webserver en toegankelijk gemaakt via internet of een intranet. Gebruikers kunnen een webapplicatie openen en ermee werken door simpelweg een URL in hun browser in te voeren, zonder dat ze iets hoeven te installeren op hun apparaat',
            voordelen: [
                {
                    titel: 'Toegankelijkheid',
                    beschrijving: 'Webapplicaties zijn toegankelijk vanaf elk apparaat met een internetverbinding, zoals een computer, tablet of smartphone. Dit maakt het gemakkelijk voor gebruikers om overal en altijd toegang te hebben tot de applicatie.',
                } , 
                {
                    titel: 'Geen installatie nodig',
                    beschrijving: 'Omdat webapplicaties via de browser worden uitgevoerd, hoeven gebruikers geen software te installeren of te updaten op hun apparaten. Dit vermindert de technische ondersteuning en vereenvoudigt het onderhoud.',
                } , 
                {
                    titel: 'Cross-platform compatibiliteit',
                    beschrijving: 'Webapplicaties zijn vaak platformonafhankelijk. Dit betekent dat ze kunnen worden gebruikt op verschillende besturingssystemen zoals Windows, macOS, Linux, Android en iOS, zolang er een compatibele browser beschikbaar is.',
                }, 
                {
                    titel: 'Centrale updates',
                    beschrijving: 'Updates en verbeteringen aan een webapplicatie kunnen centraal worden beheerd door de ontwikkelaars. Dit betekent dat zodra een update wordt uitgerold, deze onmiddellijk beschikbaar is voor alle gebruikers zonder dat zij iets hoeven te doen.',
                }, 
                {
                    titel: 'Schaalbaarheid',
                    beschrijving: 'Webapplicaties kunnen eenvoudig worden opgeschaald om grotere hoeveelheden gebruikers of gegevens te verwerken door de servercapaciteit te vergroten. Dit maakt ze geschikt voor zowel kleine als grote organisaties.',
                }, 
            ],
            img: 'sfeerfoto2.jpg'
        },
       {
            id: 3,
            dienst: 'Portaal',
            tekst: 'In de huidige digitale wereld wordt het voor organisaties steeds belangrijker om informatie en diensten op een gestroomlijnde en toegankelijke manier aan te bieden. Hier komt een portaal in beeld, een krachtig hulpmiddel dat je organisatie kan helpen om efficiëntie te verhogen, klanttevredenheid te verbeteren en processen te optimaliseren. Maar waarom is een portaal zo handig, en wanneer zou je als klant de behoefte aan een portaal kunnen hebben?',
            voordelen: [
                {
                    titel: 'Centrale Toegang tot Informatie en Diensten',
                    beschrijving: 'Een portaal biedt een gecentraliseerd toegangspunt waar gebruikers al hun benodigde informatie en diensten kunnen vinden. Of het nu gaat om klantendossiers, facturen, bedrijfsnieuws, of specifieke bedrijfsapplicaties, alles is met één enkele login te bereiken. Dit vermindert de tijd die wordt besteed aan het zoeken naar informatie en zorgt ervoor dat alles binnen handbereik is.',
                },

                {
                    titel: 'Verhoogde Efficiëntie',
                    beschrijving: 'Door alle benodigde tools en informatie op één plek te verzamelen, kunnen gebruikers hun werk sneller en efficiënter uitvoeren. Dit leidt tot tijdsbesparing, minder frustratie en uiteindelijk een hogere productiviteit. Voor bedrijven betekent dit een soepelere werking en minder operationele kosten',
                },
                {
                    titel: 'Personalisatie en Relevantie',
                    beschrijving: 'Een goed ontworpen portaal kan worden aangepast aan de specifieke behoeften en rollen van de gebruiker. Dit betekent dat elke gebruiker een gepersonaliseerde ervaring krijgt met toegang tot de voor hem of haar relevante informatie en applicaties. Dit verhoogt de efficiëntie en zorgt voor een meer gerichte en relevante gebruikerservaring.',
                },
            ],
           img: 'sfeerfoto3.jpg'
       },
        {
            id: 4,
            dienst: 'Website op maat',
            tekst: 'Een website is het eerste wat je klanten zien als je ze online opzoeken. Wij maken websites op maat, wil je zelf aan het roer staat? Dat is mogelijk. Of laat je wensen achter en wij gaan voor je aan de slag en zetten een mooi design voor je neer voordat we aan de slag gaan.',
            voordelen: [
                {
                    titel: 'Unieke uitstraling voor sterke branding',
                    beschrijving: 'Een op maat gemaakte website geeft bedrijven de kans om hun merkidentiteit volledig te laten zien. In tegenstelling tot standaard templates, biedt maatwerk de mogelijkheid om een unieke, herkenbare uitstraling te creëren die opvalt en aansluit bij de visie van het bedrijf. ',
                },
                {
                    titel: 'Volledig afgestemd op specifieke wensen',
                    beschrijving: 'Bij een op maat gemaakte website worden het ontwerp en de functies volledig afgestemd op de doelen en wensen van de klant. Dit geeft meer flexibiliteit om specifieke bedrijfsprocessen te ondersteunen, zonder de beperkingen van een kant-en-klare oplossing.',
                },
                {
                    titel: 'Schaalbaar en toekomstbestendig ',
                    beschrijving: 'Maatwerkwebsites kunnen eenvoudig meegroeien met het bedrijf. Naarmate de behoeften veranderen, kunnen nieuwe functionaliteiten of integraties worden toegevoegd, wat zorgt voor een flexibele en duurzame oplossing die op lange termijn blijft werken. ',
                },
                {
                    titel: 'Hogere prestaties en betere SEO',
                    beschrijving: 'Met een op maat gemaakte website kunnen overbodige functies worden vermeden, wat resulteert in snellere laadtijden en een betere gebruikerservaring. Bovendien kan de website optimaal worden geconfigureerd voor zoekmachines, wat de vindbaarheid en online zichtbaarheid van het bedrijf vergroot.',
                },
            
            ],
            img: 'sfeerfoto2.jpg'
        },
        {
            id: 4,
            dienst: 'Mijn werkwijze',
            tekst: 'Een gestructureerde aanpak is essentieel om een project succesvol te laten verlopen. Daarom werk ik nauw samen met u om ervoor te zorgen dat het eindresultaat voldoet aan uw verwachtingen en de behoeften van uw gebruikers. Elk project heeft unieke eisen, maar een goed gedefinieerd stappenplan zorgt ervoor dat doelen helder blijven',
            voordelen: [
                {
                    titel: 'Behoeftenanalyse & Doelstellingen',
                    beschrijving: 'Het succesvol afronden van een softwareproject begint altijd met een grondige behoeftenanalyse. Dit is de fase waarin ik me volledig richt op het begrijpen van de wensen en eisen van de klant. Wat zijn de uitdagingen die ze ervaren, en hoe kan software helpen deze op te lossen? Door in gesprek te gaan en de juiste vragen te stellen, breng ik niet alleen de functionele vereisten in kaart, maar ontdek ik ook de onderliggende doelen van het project. In deze fase ligt de nadruk op het helder formuleren van de doelstellingen. Wat moet de software precies bereiken? Welke functies zijn cruciaal, en welke kunnen eventueel later worden toegevoegd? Dit proces helpt om prioriteiten te stellen, zodat we ons kunnen richten op de meest impactvolle functionaliteiten. Het eindresultaat is een gedetailleerd overzicht van de verwachtingen en doelen, dat als basis dient voor de rest van het project. Door tijd te nemen voor een grondige analyse, voorkom ik misverstanden en zorg ik ervoor dat de software naadloos aansluit op de behoeften van de klant. ',
                },
                {
                    titel: 'Technische Specificaties & Planning',
                    beschrijving: 'Na de behoeftenanalyse is het tijd om de technische specificaties en de planning op te stellen. In deze fase vertaal ik de verzamelde wensen en doelen naar een concreet en technisch uitvoerbaar plan. Het opstellen van technische specificaties is een belangrijk onderdeel, omdat het zorgt voor een solide fundament voor de ontwikkeling. Hierin wordt gedetailleerd beschreven welke systemen er worden gebruikt, hoe de verschillende componenten met elkaar communiceren, en wat de vereisten zijn voor zaken zoals beveiliging en schaalbaarheid. Dit technische plan fungeert als een blauwdruk die de ontwikkeling in goede banen leidt.',
                },
                {
                    titel: 'Design & Prototyping',
                    beschrijving: 'In de fase van design en prototyping wordt het concept tastbaar. Hier transformeer ik de eerder vastgestelde doelen en technische specificaties naar een visuele en interactieve vorm. Het ontwerpen van de gebruikersinterface (UI) speelt hierbij een cruciale rol. Samen met de klant zorg ik ervoor dat het design niet alleen functioneel is, maar ook gebruiksvriendelijk en aantrekkelijk. Zodra het ontwerp staat, bouw ik een prototype. Dit is een vroege versie van de software, waarin de belangrijkste functionaliteiten werkend worden weergegeven. Het prototype biedt de klant de kans om de applicatie in een vroeg stadium te ervaren, feedback te geven en eventuele aanpassingen voor te stellen. Door deze aanpak wordt het ontwerp niet alleen beter afgestemd op de gebruiker, maar wordt ook het risico op dure aanpassingen later in het project geminimaliseerd.',
                },
                {
                    titel: 'Hogere prestaties en betere SEO',
                    beschrijving: 'Met een op maat gemaakte website kunnen overbodige functies worden vermeden, wat resulteert in snellere laadtijden en een betere gebruikerservaring. Bovendien kan de website optimaal worden geconfigureerd voor zoekmachines, wat de vindbaarheid en online zichtbaarheid van het bedrijf vergroot.',
                },
            
            ],
            img: 'sfeerfoto2.jpg'
        },
    ]
}

export default contentDiensten;
