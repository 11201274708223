<script>



export default{
    name: 'aboutPage',
}
</script>


<template>
    <div class="overflow-hidden">
        <div class="md:container md:mx-auto " >

            <div class="grid md:grid-cols-2 my-20 md:my-32">
                <div class="h-full w-full mx-auto rounded-full md:hidden block">
                    <img class='rounded-xl' src="../assets/sfeerfoto2.jpg" alt="maatwerk oplossingen">
                </div>

                <div class="p-5">
                    
                    <h1 class="text-black pb-4 text-[4rem]">Over mij</h1>

                
                    <p class="pb-8">
                        Eigenlijk over mij. Ik ben Swen, een Full-Stack Developer met 4+ jaar ervaring. 4+ jaar als Front-end developer en 3 jaar als Back-end developer. 
                        <br>
                        Ik houd me graag bezig met het ontwikkelen van web applicaties, portals en apps. Mijn streven is dat de applicatie veilig is, snel maar ook dynamisch moet zijn en snel moet zijn.
                        Ik ben dan ook dagelijks bezig met de nieuwste ontwikkelingen van technologie. 
                        <br>
                        Ben je opzoek naar handige tools die je bedrijf geautomatischeerd kan laten lopen d.m.v webb applicaties, IOS App of Android App of een portaal? 
                        Laten we dan samen de uitdaging aan gaan!
                    </p>
                
                    <a class="border-hoverColor border-2 p-3 mt-4 rounded-full hover:bg-hoverColor transition duration-300 text-black "><router-link to="/contact">Neem contact op</router-link></a>
                
                </div>

                <div class="h-full w-full mx-auto rounded-full hidden md:block">
                    <img class='rounded-xl' src="../assets/sfeerfoto2.jpg" alt="maatwerk oplossing">
                </div>

            </div>

            
            

        <div class="grid lg:grid-cols-2" >
            <div class="grid md:grid-cols-2 col-span-2 gap-2 h-dvh md:h-screen p-5">
                
                <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>Web Applicatie laten maken</h1>
                        <p>Heb je een webapplicatie nodig die altijd en overal toegankelijk is via de webbrowser? Voor zowel op de telefoon, tablet en desktop te gebruiken. En zoek je een oplossing die makkelijk schaalbaar is? Lees snel verder!</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 1</span>
                    </div>
                </div>
            
                <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>App Applicatie laten maken</h1>
                        <p>Een mobiele app is een softwaretoepassing die speciaal is ontworpen om te worden uitgevoerd op een mobiel apparaat, zoals een smartphone of tablet</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 2</span>
                    </div>
                </div>

            
                <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>Portaal laten maken</h1>
                        <p>In de huidige digitale wereld wordt het voor organisaties steeds belangrijker om informatie en diensten op een gestroomlijnde en toegankelijke manier aan te bieden. Hier komt een portaal in beeld, een krachtig hulpmiddel dat je organisatie kan helpen om efficiëntie te verhogen.</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 3</span>
                    </div>
                </div>

            <div class=" grid place-content-center border-2 border-solid border-black p-3 ">
                    <div class="flex justify-end">
                        <router-link to="/diensten" class=""> <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i></router-link>
                    </div>
                    <div class="text-black my-5">
                        <h1 class='mb-5 text-black'>Website laten maken</h1>
                        <p>Een website is het eerste wat je klanten zien als je ze online opzoeken. Wij maken websites op maat, wil je zelf aan het roer staat? Dat is mogelijk. Of laat je wensen achter en wij gaan voor je aan de slag en zetten een mooi design voor je neer.</p>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-[33px] font-bold text-black">/ 4</span>
                    </div>
                </div>
               

            </div>
            
            </div>
        </div>
    </div>
    


</template>

